import React from "react";
import { inject } from "mobx-react";

import ImageComponent from "./ImageComponent";
import LoadingComponent from "~/components/LoadingComponent";
import AlertComponent from "~/components/AlertComponent";
import { FILE_UPLOAD_MAX_LENGTH, IMAGE_TYPE, SHOW_TIME_MIDIA_DEFAULT, SHOW_TIME_MIDIA_MIN, TAGS_COLOR_DEFAULT, FONTS_COLOR_DEFAULT, URL_APP_APP_BASE_PATH, OPTION_TYPE } from "~/configs/constants";
import { isEmptyOrNullStr, isEmptyOrNullInt, getVarFromUrl } from "../../helpers/utils";
import { PAGES_IMAGES_TAB } from "../../configs/constants";
import "share-api-polyfill";

@inject("ImageStore", "DeviceStore", "FileStore", "ProductStore", "FontStore", "PageStore", "OrganizationStore")
class ImageContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      image: {},
      searchText:"",
      selectedFile: null,
      products:[],
    };
  }

  async componentDidMount() {
    await this.getImage();

    try {
      const { font } = this.state;
      document.getElementById("font").style.fontFamily = font.name;
    } catch{}
  }

  onFileChange = async event => {
    const { image } = this.state;
    const fileType = event.target.files[0].type;
    const fileSize = event.target.files[0].size;
    if (fileSize > FILE_UPLOAD_MAX_LENGTH) {
      document.getElementById("uploadCaptureInputMidia").value = "";
      this.setState({ selectedFile: null });
      AlertComponent.error("Limite máximo de envio excedido");
    }
    else if ((image.type === IMAGE_TYPE.image &&
              fileType !== 'image/png' &&
              fileType !== 'image/gif' &&
              fileType !== 'image/bmp' &&
              fileType !== 'image/jpeg') ||
              (image.type === IMAGE_TYPE.video &&
              fileType !== 'video/mp4')){
    
      document.getElementById("uploadCaptureInputMidia").value = "";
      this.setState({ selectedFile: null });
      AlertComponent.error("Formato de mídia não suportado");
    }
    else 
      this.setState({ selectedFile: event.target.files[0] });
  };

  getImage = async () => {
    this.setState({
      isLoading: true,
    });

    const { ImageStore, DeviceStore, history } = this.props;
    let pageId = getVarFromUrl("pageId");
    let deviceId = getVarFromUrl("deviceId");
    const imageId = getVarFromUrl("imageId");    
    const hasImage = imageId !== undefined && parseInt(imageId) > 0;    
 
    await DeviceStore.setDeviceId(deviceId);
    let device = await DeviceStore.getDevice();

    let image = {};
    if (!hasImage){
      image.deviceId = deviceId;
      image.pageId = pageId;
      image.type = IMAGE_TYPE.image;
      image.showTime = SHOW_TIME_MIDIA_DEFAULT;       
      image.boxColor = TAGS_COLOR_DEFAULT;
      image.fontColor = FONTS_COLOR_DEFAULT;
    }
    else {
      image = await ImageStore.getImage(imageId);
      if (image === undefined || image === null){
        history.push("devices");
        return;
      }
      pageId = image.pageId;
      deviceId = image.deviceId;
    }
    
    if (image.name === undefined || image.name === null) image.name = "";

    let imagesContainer = await this.getImagesContainer('', '', image);    
    let imageContainer = await this.getImageContainer(imagesContainer, image);
    
    let productResult = await this.getProduct(image);
    let product = productResult.product;
    let products = productResult.products;

    let layouts = await this.getLayouts("", image, device);   
    let layout = await this.getLayout(layouts, image);
    
    let fonts = await this.getFonts("");   
    let font = await this.getFont(fonts, image);
   
    this.setState({
      isLoading: false,
      device,
      image,
      imagesContainer,
      imageContainer,
      products,
      product,      
      layout,
      layouts,
      font,
      fonts
    });
  };

  getImagesContainer = async (filter, imageName, image) => {
    const { FileStore } = this.props
    let imagesContainer = [];
    let imagesContainerData = null;
    if (isEmptyOrNullStr(imageName)) imagesContainerData = await FileStore.getImages(filter);
    else imagesContainerData = await FileStore.getImagesByName(imageName);
    if (imagesContainerData === null) return null;

    let hasFolderImage = (image.folderImage !== undefined && image.folderImage !== null && image.folderImage.id > 0);
    let folderImageId = 0;
    if (hasFolderImage) folderImageId = image.folderImage.id;
    let folderImageFound = false;   
    for (let i = 0; i < imagesContainerData.length; i++) {
      if (imagesContainerData[i].id === folderImageId) folderImageFound = true;
      imagesContainer.push(
        {
          id: imagesContainerData[i].id,
          url: imagesContainerData[i].url,
          name: imagesContainerData[i].description,
          label: imagesContainerData[i].description,
          extension: imagesContainerData[i].extension,          
          type: OPTION_TYPE.image
        }
      );
    }

    if (hasFolderImage && !folderImageFound)
      imagesContainer.push(
      {
        id: image.folderImage.id,
        url: image.folderImage.url,
        name: image.folderImage.description,
        label: image.folderImage.description,
        extension: image.folderImage.extension,
        type: OPTION_TYPE.image
      });

    return imagesContainer;
  };

  getImageContainer = async (imagesContainer, image) => {
    let imageContainer = {};
    if (imagesContainer !== undefined && imagesContainer !== null && imagesContainer.length > 0) imageContainer = imagesContainer[0];
    for (var i = 0; i < imagesContainer.length; i++){
      if (imagesContainer[i].url === image.path){
        imageContainer = imagesContainer[i];
        break;
      }
    };
    return imageContainer;
  };

  getProducts = async (filter, image) => {
    if (filter === "Nenhum ") filter = "";
    const { ProductStore } = this.props
    let products = [];
    let productsData = await ProductStore.searchProductsByDevice(filter, image.deviceId);
    for(let i = 0; i < productsData.length; i++) {
      products.push(this.makeProductItem(productsData[i]));
    }
    return products;
  };

  makeProductItem = (product) => {
    return {
      id: product.productId,
      name: product.name + " " + product.code,
      label: product.name + " " + product.code,
      productId: product.productId,
      imagePath: product.imagePath,
      imageName: this.imageNameMake(product.imagePath),
      code: product.code,
      valueStr: product.valueStr,
      description: product.description,
      originalName: product.name,
      value: product.value
    };
  }

  imageNameMake = (imageUrl) => {
    try {
      return imageUrl.split("fileName=")[1];
    } catch {
      return null;
    }
  };

  getProduct = async (image) => {
    let products = await this.getProducts("", image);
    let result = {};
    if (image.product != null && image.product.productId > 0)
      result.product = this.makeProductItem(image.product);
    else 
      result.product = products[0];
    
    if (!this.isProductList(result.product, products))
      products.push(result.product);
    
    result.products = products;
    return result;
  }

  isProductList = (product, products) => {
    if (product === undefined || product === null || product.productId === undefined) return false;
    for (var i = 0; i < products.length; i++){
      if (products[i].productId === product.productId){
        return true;
      }
    }; 
    return false;
  };

  isImageContainerList = (imageContainer, imagesContainer) => {
    if (imageContainer === undefined || imageContainer === null || imageContainer.id === undefined) return false;
    for (var i = 0; i < imagesContainer.length; i++){
      if (imagesContainer[i].id === imageContainer.id){
        return true;
      }
    }; 
    return false;
  };

  getLayouts = async (filter, image, device) => {
    const { FileStore } = this.props
    let layouts = [];
    let layoutsData = await FileStore.getDesigns(filter, device.orientation);
    let hasDesign = (image.design !== undefined && image.design !== null && image.design.id > 0);
    let designId = 0;
    if (hasDesign) designId = image.design.id;
    let designFound = false;
    for(let i = 0; i < layoutsData.length; i++) {
      if (layoutsData[i].id === designId) designFound = true;
      layouts.push(
        {
          id: layoutsData[i].id,
          url: layoutsData[i].url,
          name: layoutsData[i].name.replace(".jpg",""),
          label: layoutsData[i].name.replace(".jpg",""),
          type: OPTION_TYPE.layout
        });
    }

    if (hasDesign && !designFound)
      layouts.push(
      {
        id: image.design.id,
        url: image.design.url,
        name: image.design.name.replace(".jpg",""),
        label: image.design.name.replace(".jpg",""),
        type: OPTION_TYPE.layout
      });

    return layouts;   
  };

  getLayout = async (layouts, image) => {
    let layout = {};
    if (layouts !== undefined && layouts !== null && layouts.length > 0) layout = layouts[0];
    for (var i = 0; i < layouts.length; i++){
      if (layouts[i].url === image.layoutLinkUrl){
        layout = layouts[i];
        break;
      }
    }; 
    return layout;
  };

  getFonts = async (filter) => {
    const { FontStore } = this.props
    let fonts = [];
    let fontsData = await FontStore.getFonts(filter);
    for (let i = 0; i < fontsData.length; i++) {
      this.cssFontFaceMidia(fontsData[i].name, fontsData[i].url);
      fonts.push(
        {
          id: fontsData[i].fontId,          
          name: fontsData[i].name,
          label: fontsData[i].name,
          cssName: fontsData[i].cssName,
          url: fontsData[i].url,
          type: OPTION_TYPE.font
        }
      );
    }
    return fonts;   
  };

  cssFontFaceMidia = (name, url) => {     
    var existingStyles = document.querySelectorAll("style");
    var fontExists = false;
    
    existingStyles.forEach((style) => {
      if (style.innerHTML.includes(`font-family: '${name}'`)) {
        fontExists = true;
      }
    });
    
    if (fontExists) return;

    var newStyle = document.createElement('style');
    newStyle.appendChild(document.createTextNode(`\
    @font-face {\
      font-family: '${name}';\
      src: url('${url}');\
    }\
    `));
    document.head.appendChild(newStyle);    
  };

  getFont = async (fonts, image) => {
    let font = {};
    if (fonts !== undefined && fonts !== null && fonts.length > 0) font = fonts[0];
    for (var i = 0; i < fonts.length; i++){
      if (fonts[i].id === image.fontId){
        font = fonts[i];
        break;
      }
    }; 
    return font;
  };

  goDevice = () => {
    const { history } = this.props;
    history.push(`page?deviceId=${getVarFromUrl("deviceId")}&pageId=${getVarFromUrl("pageId")}&tab=${PAGES_IMAGES_TAB}`);
  };

  maintainImage = async () => {
    this.setState({
      isLoading: true
    });

    const { image, selectedFile } = this.state;
    const { ImageStore } = this.props;

    if (isEmptyOrNullStr(image.showTime) || image.showTime < SHOW_TIME_MIDIA_MIN) AlertComponent.error("Tempo de exibição da mídia deve ser maior ou igual a " + SHOW_TIME_MIDIA_MIN + " segundos");
    else if (isEmptyOrNullStr(image.type) || (image.type !== IMAGE_TYPE.image && image.type !== IMAGE_TYPE.video && image.type !== IMAGE_TYPE.folder && image.type !== IMAGE_TYPE.notice)) AlertComponent.error("Nenhum tipo de mídia selecionado");
    else if (image.imageId === undefined && image.type !== IMAGE_TYPE.folder && image.type !== IMAGE_TYPE.notice && selectedFile == null) AlertComponent.error("Nenhum arquivo selecionado");
    else if (image.type === IMAGE_TYPE.folder && isEmptyOrNullStr(image.path)) AlertComponent.error("Nenhuma imagem do produto selecionada");
    else if (image.type === IMAGE_TYPE.folder && isEmptyOrNullStr(image.layoutLinkUrl)) AlertComponent.error("Nenhum tema selecionado");
    else if (image.type === IMAGE_TYPE.folder && isEmptyOrNullInt(image.productId)) AlertComponent.error("Nenhum produto selecionado");
    else {
      const formData = new FormData();
      if (selectedFile !== undefined && selectedFile !== null) formData.append(selectedFile.name, selectedFile);
      formData.append("payload", JSON.stringify(image));

      const result = await ImageStore.maintainImage(formData);

      if (result === "") {
        AlertComponent.success("Mídia salva com successo");
        this.goDevice();
      }
      else 
        AlertComponent.error(result);
    }

    this.setState({
      isLoading: false
    });
  };

  onInputChangeImageContainer = async (event, value) => {
    const { image } = this.state;
    if (event !== null) {
      if (event.type === "change") {
        try {
          this.setState({
            imageIsLoading: true
          });
          const { imageContainer } = this.state;
          let imagesContainer = await this.getImagesContainer(value, '', image);

          if (!this.isImageContainerList(imageContainer, imagesContainer))
            imagesContainer.push(imageContainer);

          this.setState({
            imagesContainer: imagesContainer,
            imageIsLoading: false
          });
        }
        catch {}
      }
      else {
        try {    
          const { image, imageContainer, imagesContainer } = this.state;
          
          let imageContainerSelected = imageContainer;
          for (var i = 0; i < imagesContainer.length; i++){
            if (imagesContainer[i].name === value){
              imageContainerSelected = imagesContainer[i];
              break;
            }
          }

          image.path = imageContainerSelected.url;
          image.fileName = imageContainerSelected.name;
          image.extension = imageContainerSelected.extension;
          this.setState({
            imageContainer: imageContainerSelected,
            image: image
          });
        }
        catch {}
      }
    }
  };

  onChange = async (e) => {
    const { image } = this.state;
    const updatedImage = {
      ...image,
      [e.target.name]: e.target.value     
    }

    this.setState({
      image: updatedImage
    });
  };

  onInputChangeProduct = async (event, value) => {
    if (event !== null) {
      try { 
          if (event.type === "change") {
            this.setState({
              productIsLoading: true,
            });
            const { image, product } = this.state;
            let products = await this.getProducts(value, image);
      
            if (!this.isProductList(product, products))
              products.push(product);
      
            this.setState({
              products,
              productIsLoading: false
            });
          }
          else {
            const { products, product } = this.state;
            let productSelected = product;
            for (var i = 0; i < products.length; i++){
              if (products[i].name === value){
                productSelected = products[i];
                break;
              }
            }
            await this.onChangeProduct(productSelected);            
          }          
        }
      catch { }
    }
  };

  onChangeProduct = async (product) => {
    this.setState({
      isLoading: true
    });

    try {     
      const { FileStore } = this.props
      const { products } = this.state;
      let searchProduct;

      if (isEmptyOrNullStr(product?.imagePath)) {   
        searchProduct = await this.searchProduct(product);        
      }
      else {
        const isValid = await FileStore.isValid(product?.imagePath);
        if (isValid) {
          searchProduct = await this.searchProduct(product);
        }
        else { 
          product.imagePath = "";
          searchProduct = await this.searchProduct(product);
        }
      }

      if (!this.isProductList(searchProduct.product, products))
        products.push(product);

      this.setState({
        imagesContainer: searchProduct.updatedImagesContainer,
        imageContainer: searchProduct.updatedImageContainer,
        products: products,
        product: searchProduct.product,        
        image: searchProduct.updatedImage
      });
    }
    catch {}

    this.setState({
      isLoading: false
    });
  };

  searchProduct = async (product) => {
    const { imagesContainer, imageContainer, image } = this.state;
    let updatedImageContainer = imageContainer;
    let updatedImagesContainer = imagesContainer;
    let updatedImage = image;            
    updatedImage.product = product
    updatedImage.productId = product.productId;

    if (isEmptyOrNullStr(product.imageName)){
      updatedImagesContainer = await this.getImagesContainer(product?.code, '', image);
      if (updatedImagesContainer !== null && updatedImagesContainer.length === 2) {
        updatedImageContainer = updatedImagesContainer[1];
        product.imagePath = updatedImageContainer.url;
        updatedImage.path = updatedImageContainer.url;
      }
      else {
        updatedImagesContainer = await this.getImagesContainer(product?.name, '', image);
        if (updatedImagesContainer === null) updatedImagesContainer = await this.getImagesContainer('', '', image);
      }      
    }
    else {      
      updatedImagesContainer = await this.getImagesContainer('', product.imageName, image);
      if (updatedImagesContainer !== null && updatedImagesContainer.length === 2) {
        updatedImageContainer = updatedImagesContainer[1];
        product.imagePath = updatedImageContainer.url;
        updatedImage.path = updatedImageContainer.url;
      }
    }
    return {
      updatedImageContainer: updatedImageContainer,
      updatedImagesContainer: updatedImagesContainer,
      updatedImage: updatedImage,
      product: product
    }
  }

  onChangeLayout = (layoutItem) => {
    const { image, layout } = this.state; 
    if (layoutItem === null || layoutItem === undefined) layoutItem = layout;

    image.layoutLinkUrl = layoutItem.url; 
    this.setState({
      layout: layoutItem,
      image: image
    });        
  };

  onKeyUpLayout = async () => {
    try {
      this.setState({
        layoutIsLoading: true,
      });      
      const { device } = this.state;
      let filter = document.getElementById("layout").value;
      const { image } = this.state;
      let layouts = await this.getLayouts(filter, image, device);
      this.setState({
        layouts,
      });
      this.setState({
        layoutIsLoading: false,
      });
    }
    catch {}
  }

  onChangeBoxColor = (color) => {
    const { image } = this.state;  
    image.boxColor = color.hex;
    this.setState({
      image: image,
    });
  };

  onChangeFontColor = (color) => {
    const { image } = this.state;  
    image.fontColor = color.hex;
    this.setState({
      image: image,
    });
  };

  onChangeFont = (fontItem) => {
    const { image } = this.state;
    image.fontId = fontItem.id;
    this.setState({
      font: fontItem,
      image: image
    });        
  };

  onKeyUpFont = async () => {
    try {
      this.setState({
        fontIsLoading: true,
      });

      let filter = document.getElementById("font").value;
      let fonts = await this.getFonts(filter);
      this.setState({
        fonts,
      });
      this.setState({
        fontIsLoading: false,
      });
    }
    catch {}
  };

  imageGenerate = async () => {
    const { OrganizationStore } = this.props;    
    const { image, fonts, product, device } = this.state;

    if (image.type !== IMAGE_TYPE.notice && isEmptyOrNullStr(image.path))
      AlertComponent.error("Mídia ainda não foi inserida, clique em salvar");
    else {
      image.font = await this.getFont(fonts, image);

      let customImage = {
        path: image.path,
        type: image.type
      };
      
      if (image.type === IMAGE_TYPE.folder) {
        customImage = {
          ...customImage,
          path: image.path,
          layoutLinkUrl: image.layoutLinkUrl,
          boxColor: image.boxColor,
          fontColor: image.fontColor,
          organizationLogoUrl: OrganizationStore.getOrganizationLogoUrl(),
          product: {
            name: product.originalName,
            value: product.value,
            description: product.description
          },
          font: {
            cssName: image.font.cssName,
            url: image.font.url,
          },
          orientation: device.orientation 
        };
      }      
      else if (image.type === IMAGE_TYPE.notice) {
        customImage = {
          ...customImage,
          path: image.path,
          layoutLinkUrl: image.layoutLinkUrl,
          boxColor: image.boxColor,
          fontColor: image.fontColor,
          noticeTitle: image.noticeTitle,
          noticeMessage: image.noticeMessage,
          organizationLogoUrl: OrganizationStore.getOrganizationLogoUrl(),    
          font: {
            cssName: image.font.cssName,
            url: image.font.url,
          },
          orientation: device.orientation
        };
      }
      
      var imageBase64 = btoa(JSON.stringify(customImage));
      var path = URL_APP_APP_BASE_PATH + "?midiaView=" + imageBase64;
      return path;
    }
  };

  imageGenerate = async () => {
    const { OrganizationStore } = this.props;    
    const { image, fonts, product, device } = this.state;

    if (image.type !== IMAGE_TYPE.notice && isEmptyOrNullStr(image.path))
      AlertComponent.error("Mídia ainda não foi inserida, clique em salvar");
    else {
      image.font = await this.getFont(fonts, image);

      let customImage = {
        path: image.path,
        type: image.type
      };
      
      if (image.type === IMAGE_TYPE.folder) {
        customImage = {
          ...customImage,
          path: image.path,
          layoutLinkUrl: image.layoutLinkUrl,
          boxColor: image.boxColor,
          fontColor: image.fontColor,
          organizationLogoUrl: OrganizationStore.getOrganizationLogoUrl(),
          product: {
            name: product.originalName,
            value: product.value,
            description: product.description
          },
          font: {
            cssName: image.font.cssName,
            url: image.font.url,
          },
          orientation: device.orientation 
        };
      }      
      else if (image.type === IMAGE_TYPE.notice) {
        customImage = {
          ...customImage,
          path: image.path,
          layoutLinkUrl: image.layoutLinkUrl,
          boxColor: image.boxColor,
          fontColor: image.fontColor,
          noticeTitle: image.noticeTitle,
          noticeMessage: image.noticeMessage,
          organizationLogoUrl: OrganizationStore.getOrganizationLogoUrl(),    
          font: {
            cssName: image.font.cssName,
            url: image.font.url,
          },
          orientation: device.orientation
        };
      }
      
      var imageBase64 = btoa(JSON.stringify(customImage));
      var path = URL_APP_APP_BASE_PATH + "?midiaView=" + imageBase64;
      return path;
    }
  };

  onShow = async () => {
    window.open(await this.imageGenerate());
  }

  onShare = async (imageUrl) => {    
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const file = new File([blob], "foto.jpg", { type: "image/jpeg" });

      const url = URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = file.name;
      document.body.appendChild(a);

      console.log(url);

      if (navigator.canShare && navigator.canShare({ files: [file] })) {
        await navigator.share({
          files: [file],
          title: 'Compartilhando um link',
          text: 'Confira esta imagem incrível!'
        });

        AlertComponent.success('Imagem compartilhada com sucesso!');
      } else {        
        await navigator.share({
          title: 'Compartilhando um link',
          text: 'Confira esta imagem incrível!',
          url: imageUrl,
        });
  
        AlertComponent.success('Link compartilhado com sucesso!');
      }
    } catch (error) {        
      if (navigator.clipboard) {
        try {
          await navigator.clipboard.writeText(imageUrl);          
          AlertComponent.success('Não foi possível compartilhar diretamente. O link foi copiado para sua área de transferência.');
        } catch (clipboardError) {
          AlertComponent.error('Erro ao copiar para a área de transferência:', clipboardError);
        }
      } else {
        AlertComponent.error('Não foi possível compartilhar ou copiar o link. Por favor, copie manualmente: ' + imageUrl);
      }
    }
  }

  render() {
    const { isLoading, image, imageContainer, imagesContainer, products, product, 
      productIsLoading, imageIsLoading, layouts, layout, layoutIsLoading, fontIsLoading,
      fonts, font } = this.state;
    return (
      <>
        {(isLoading && (
          <>
            <LoadingComponent />
          </>
        )) || (
          <div className="content content-xs" style={{overflow: "hidden"}} >
            <ImageComponent
              image={image}
              imageContainer={imageContainer}
              imagesContainer={imagesContainer}
              onClick={this.maintainImage}
              onCancel={this.goDevice}
              onChange={this.onChange}
              onFileChange={this.onFileChange}
              onInputChangeImageContainer={this.onInputChangeImageContainer}     
              products={products}
              product={product}
              onInputChangeProduct={this.onInputChangeProduct}
              productIsLoading={productIsLoading}      
              imageIsLoading={imageIsLoading} 
              onChangeLayout={this.onChangeLayout}
              layout={layout}
              layouts={layouts}
              onKeyUpLayout={this.onKeyUpLayout}
              layoutIsLoading={layoutIsLoading}
              onChangeBoxColor={this.onChangeBoxColor}
              onChangeFontColor={this.onChangeFontColor}
              fontIsLoading={fontIsLoading}
              onChangeFont={this.onChangeFont}
              onKeyUpFont={this.onKeyUpFont}
              fonts={fonts}
              font={font}
              onShow={this.onShow}
              onShare={this.onShare}
            />            
          </div>
        )}
      </>
    );
  }
}

export default ImageContainer;