import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Row, Col } from "reactstrap";
import { OPTION_TYPE } from "../../configs/constants";

const OptionItem = inject("FileStore")(observer(({ option, FileStore }) => {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const loadImage = async () => {
      try {
        if (isMounted) setImageUrl(await FileStore.getBlob(option.url));
      } catch (error) {
        console.error("Erro ao carregar a imagem:", error);
      }
    };

    loadImage();

    return () => { isMounted = false; };
  }, [option.url, FileStore]);

  if (option.type === OPTION_TYPE.layout) {
    return (
      <Row>
        <Col xs={12} md={12} style={{ textAlign: "center" }}>{option.name}</Col>
        <br />
        <Col xs={12} md={12} style={{ textAlign: "center" }}>
          {imageUrl ? <img src={imageUrl} width="30%" alt="" /> : <p>Carregando...</p>}
        </Col>
      </Row>
    );
  } else if (option.type === OPTION_TYPE.font) {
    return (
        <Row>
          <Col xs={12} md={12} style={{textAlign: "left"}}>
            <div style={{fontFamily: `${option.name}`}}>{option.name}</div>
          </Col>                              
        </Row>
    );
  } else if (option.type === OPTION_TYPE.image) {
    return (
      <div style={{textAlign: "center", width: "100%"}}>
        <div>
          {option.name}
        </div>
        <div>
          <img src={imageUrl} style={{width: "10%"}} alt=""/>
        </div>
      </div>
    );
  }
  else {
    return (
      <></>
    )
  }
}));

export default OptionItem;