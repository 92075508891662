/* eslint-disable no-else-return */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-string-refs */
/* eslint-disable no-undef */
import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { inject } from "mobx-react";
import PerfectScrollbar from "perfect-scrollbar";

import AdminNavbar from "~/components/AdminNavbar";
import Sidebar from "~/components/Sidebar";
import logo from "~/assets/logo.png";
import { getMenus } from "~/configs/menu";
import AlertComponent from "~/components/AlertComponent";

let ps;

@inject("FileStore", "UserStore", "DeviceStore", "HangtagStore", "ImageStore", "OrganizationStore", "PageStore", "ProductStore", "FontStore")
class HomeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {     
      backgroundColor: "blue",
      sidebarOpened:
        document.documentElement.className.indexOf("nav-open") !== -1,
    };   
  }

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      const { mainPanel } = this.refs;
      ps = new PerfectScrollbar(mainPanel, { suppressScrollX: true });
      const tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }

    const { FileStore, FontStore } = this.props;
    FileStore.maintainDesigns();
    FontStore.maintainFonts();
  }

  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      if (navigator.platform.indexOf("Win") > -1) {
        const tables = document.querySelectorAll(".table-responsive");
        for (let i = 0; i < tables.length; i++) {
          ps = new PerfectScrollbar(tables[i]);
        }
      }
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }

  goLogout = async () => {
    const { FileStore, UserStore, DeviceStore, HangtagStore, ImageStore, OrganizationStore, PageStore, ProductStore, FontStore, history } = this.props;
    FontStore.logout();
    FileStore.logout();
    UserStore.logout();
    DeviceStore.logout();
    HangtagStore.logout();
    ImageStore.logout();
    OrganizationStore.logout();
    PageStore.logout();
    ProductStore.logout();

    history.push("/");
  };

  goBlobImportation = async () => {
    const { FileStore } = this.props;
    const result = await FileStore.importation();
    if (result){
      FileStore.logout();
      AlertComponent.success("Importação realizada com sucesso");
    }
    else AlertComponent.error("Falha ao realizar importação");
  };


  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    const { sidebarOpened } = this.state;
    document.documentElement.classList.toggle("nav-open");
    this.setState({ sidebarOpened: !sidebarOpened });
  };

  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };

  getRoutes = (routes) => {
    if (routes != null && routes !== undefined)
      return routes.map((prop, key) => {
        if (prop.layout === "/home") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              // eslint-disable-next-line react/no-array-index-key
              key={key}
            />
          );
        }
        return null;
      });
    else return <></>;
  };

  render() {
    const { UserStore } = this.props;
    const { name, sub } = UserStore.getToken();
    const { backgroundColor, sidebarOpened } = this.state;
    const level = UserStore.getLevel();
    const menus = getMenus(level);
    return (
      <>       
        <div className="wrapper">
          <Sidebar
            {...this.props}
            routes={menus}
            bgColor={backgroundColor}
            logo={{
              user: { name, sub },
              imgSrc: logo,
              outterLink: "https://eprice.net.br"
            }}
            toggleSidebar={this.toggleSidebar}
          />
          <div className="main-panel" ref="mainPanel" data={backgroundColor}>
            <AdminNavbar
              {...this.props}
              brandText=""
              user={{ name, document, level }}
              toggleSidebar={this.toggleSidebar}
              sidebarOpened={sidebarOpened}
              goLogout={this.goLogout}
              goBlobImportation={this.goBlobImportation}
            />
            <Switch>
              {this.getRoutes(menus)}
              <Redirect from="*" to="/home/connect-device" />
            </Switch>
          </div>
        </div>
      </>
    );
  }
}

export default HomeContainer;
