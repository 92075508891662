import { observable, action } from "mobx";
import fontService from "../services/FontService";
import { maintain, getBlobUrl } from "../configs/indexedDB";


export class FontStore {
  @observable fonts = [];

  @action.bound
  getBlob = async (url) => {
    return await getBlobUrl(url);
  };
  
  @action.bound
  maintainFonts = async () => {
    let fonts = await this.getFonts("");
    maintain(fonts);
  };

  @action.bound
  getFonts = async (filter) => {
    if (this.fonts.length > 0 && (filter === "" && filter !== "Nenhum")) return this.fonts;
    const data = await fontService.getFonts(filter);
    if (data === null || data === "" || data === undefined) return null;
    let resultData = [];
    let item = {
      id: 0,
      name: "Nenhum",
      url: ""
    };
    resultData.push(item);
    resultData.push.apply(resultData, data)
    this.fonts = resultData;
    return this.fonts;
  };

  @action.bound
  logout = () => {
    this.fonts = [];
  };
}

const fontStore = new FontStore();
export default fontStore;
