
const dbName = "EpriceManager90";
const dbTableName = "Blobs";

var openDB = function() {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(dbName, 1);
        
        request.onupgradeneeded = function(event) {
            const db = event.target.result;
            if (!db.objectStoreNames.contains(dbTableName)) {
                db.createObjectStore(dbTableName);
            }
        };

        request.onsuccess = function(event) {
            resolve(event.target.result);
        };

        request.onerror = function(event) {
            reject(event.target.error);
        };
    });
};

const saveToIndexedDB = async (key, blob) => {
    const db = await openDB();
    return new Promise((resolve, reject) => {
        const transaction = db.transaction(dbTableName, "readwrite");
        const store = transaction.objectStore(dbTableName);
        store.put(blob, key);

        transaction.oncomplete = () => resolve();
        transaction.onerror = (event) => reject(event.target.error);
    });
};

const getFromIndexedDB = async (key) => {
    const db = await openDB();
    return new Promise((resolve, reject) => {
        const transaction = db.transaction(dbTableName, "readonly");
        const store = transaction.objectStore(dbTableName);
        const request = store.get(key);

        request.onsuccess = function(event) {
            resolve(event.target.result);
        };

        request.onerror = function(event) {
            reject(event.target.error);
        };
    });
};

export const getBlobUrl = async (url) => {
    try {
        await openDB();
        let blob = await getFromIndexedDB(url);
        if (blob) return URL.createObjectURL(blob);
        else {
            const response = await fetch(url);
            blob = await response.blob();
            if (blob) await saveToIndexedDB(url, blob);
            return URL.createObjectURL(blob);
        }
    }
    catch(error){
        console.log(error);
    }
};


export const maintain = async (datas) => {
    try {
        for (let i = 0; i < datas.length; i++){
            let blob = await getFromIndexedDB(datas[i].url);
            if (blob) continue;

            if (datas[i].url !== null && datas[i].url !== '') {
                try 
                {
                    const response = await fetch(datas[i].url);
                    blob = await response.blob();
                    if (blob) await saveToIndexedDB(datas[i].url, blob);
                }
                catch {}            
            }            
        }
    } catch (error) {
        console.error('Erro ao baixar a imagem:', error);
        return null;
    }
};