import { observable, action } from "mobx";
import fileService from "../services/FileService";
import { FILE_CONTAINER, ORIENTATION } from "../configs/constants";
import { maintain, getBlobUrl } from "../configs/indexedDB";

export class FileStore {
  @observable globalDesignsH = [];
  @observable globalDesignsV = [];
  @observable globalImages = [];

  @action.bound
  getBlob = async (url) => {
    return await getBlobUrl(url);
  };

  @action.bound
  maintainDesigns = async () => {
    let designsH = await this.getDesigns("", ORIENTATION.horizontal);
    let designsV = await this.getDesigns("", ORIENTATION.vertical);
    let images = await this.getImages("");
    let datas = [];
    datas.push.apply(datas, designsH)
    datas.push.apply(datas, designsV)
    datas.push.apply(datas, images)
    maintain(datas);
  };

  @action.bound
  getDesigns = async (filter, orientation) => {
    let data = {};
    if (orientation === ORIENTATION.horizontal) {
      if (this.globalDesignsH.length > 0 && filter === "") return this.globalDesignsH;
      data = await fileService.getFileBlobs(FILE_CONTAINER.designs, filter);
    }
    else {
      if (this.globalDesignsV.length > 0 && (filter === "" && filter === "Nenhum")) return this.globalDesignsV;
      
      data = await fileService.getFileBlobs(FILE_CONTAINER.designsVertical, filter);
    }
    if (data === null || data === "" || data === undefined) return null;  
    maintain(data);
    let resultData = [];
    let item = {
      id: 0,
      url: "",
      name: "Nenhum"
    };
    resultData.push(item);
    resultData.push.apply(resultData, data);
    if (orientation === ORIENTATION.horizontal)
      this.globalDesignsH.push.apply(this.globalDesignsH, resultData);
    else
      this.globalDesignsV.push.apply(this.globalDesignsV, resultData);

    return resultData;
  };

  @action.bound
  getImages = async (filter) => {
    if (this.globalImages.length > 0 && (filter === "" && filter === "Nenhum")) return this.globalImages;

    let resultData = [];
    let item = {
      id: 0,
      url: "",
      description: "Nenhum"
    };
    resultData.push(item);
    const data = await fileService.getFileBlobs(FILE_CONTAINER.images, filter);
    if (data === null || data === "" || data === undefined) return resultData;     
    maintain(data);  
    resultData.push.apply(resultData, data)
    this.globalImages.push.apply(this.globalImages, resultData);
    return resultData
  };

  @action.bound
  getImagesByName = async (name) => {
    let resultData = [];
    let item = {
      id: 0,
      url: "",
      description: "Nenhum"
    };
    resultData.push(item);
    let data = await fileService.getFileBlobsByName(FILE_CONTAINER.images, name);
    if (data === null || data === "" || data === undefined) return resultData;  
    resultData.push.apply(resultData, data)    
    return resultData;
  };

  @action.bound
  isValid = async (url) => {
    return await fileService.isValid(url);
  };

  @action.bound
  importation = async () => {
    return await fileService.importation();
  };  

  @action.bound
  logout = () => {
  };

  @action.bound
  getByOrganizationId = async (organizationId, skip) => {
    return await fileService.getByOrganizationId(organizationId, skip);
  };  

}

const fileStore = new FileStore();
export default fileStore;
